.logo {
  max-width: 150px;
}
.logoContainer {
  padding-left: 15px;
}
.social-container svg .social-svg-mask path {
  fill: #e86cb0;
}

[title~="flower"] {
  border: 5px solid yellow;
}
a[title~="ManyJorbs"] .social-container svg .social-svg-mask path {
  fill: #6780ff;
}

.background_image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-image: url("https://www.jorbs.tv/images/header_background.png");
}

@media (max-width: 768px) {
  .background_image {
    background-image: none;
  }
  .logoContainer {
    text-align: center;
    padding-left: 0;
  }
}

.tabletOrSmaller {
  display: none;
}
.desktopOrLarger {
  margin-top: 1em;
}
.desktopOrLarger a {
  margin-left: 5px;
  margin-right: 5px;
}
@media (max-width: 898px) {
  .background_image {
    background-image: none;
  }
  .MuiGrid-root {
    justify-content: center !important;
  }
  .tabletOrSmaller {
    display: block !important;
    float: right;
    width: 100%;
    text-align: center;
  }
  .tabletOrSmaller a {
    margin-left: 5px;
    margin-right: 5px;
  }
  .desktopOrLarger {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 1100px) {
  .socialMedia a {
    width: 35px !important;
    height: 35px !important;
  }
  .background_image {
    background-size: auto;
  }
}
