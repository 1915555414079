html {
  background-color: #1a2555;
  font-size: 18px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.imageBorder {
  border: 1px solid #000000;
}

@media (max-width: 1068px) {
  .fullMobile,
  .fullTablet {
    grid-column: span 11 !important;
    padding-left: 0em;
  }
  .hideMobile,
  .hideTablet {
    display: none;
  }
}
@media (max-width: 768px) {
  .margin_remove_mobile {
    margin-left: 0 !important;
  }
  .text_align_center {
    text-align: center;
  }
}
