input {
  margin: 10px;
  background-color: white !important;
  border-radius: 10px;
  color: black !important;
}
.contact_picture {
  border-radius: 10px;
}
.MuiFilledInput-root {
  padding: 0px !important;
}
.MuiTextField-root {
  width: 100%;
}
textarea {
  margin: 10px;
  color: black !important;
  background-color: white !important;
  padding: 13px !important;
  border-radius: 10px;
}
label {
  display: none;
}
button {
  color: white !important;
}
a {
  color: white;
}
.MuiInputBase-root {
  margin-bottom: 10px !important;
}
fieldset {
  background-color: white !important;
}
