.broadcastPicture {
  width: 100%;
  float: right;
}

h2 {
  margin: 0px;
  font-size: 2.35em;
  text-transform: uppercase;
}
h3 {
  font-weight: normal;
  font-size: 1.5em;
  text-transform: uppercase;
  margin: 0px;
}
h4 {
  color: #f9458f;
  text-transform: uppercase;
  margin: 0px;
}

.list {
  margin-top: 2em;
}
.list .secondaryCircle {
  display: table-cell;
  border-radius: 50%;
  border: 5px solid #f9458f;
  text-transform: uppercase;
  height: 130px;
  width: 130px;
  max-width: 130px;
  max-height: 130px;
  min-width: 130px;
  max-width: 130px;
  vertical-align: middle;
  text-align: center;
  font-weight: bold;
}
.list .secondaryCircle span {
  font-size: 30px;
}

.list .text {
  vertical-align: middle;
  text-align: left;
  display: table-cell;
}
.list .text strong {
  font-size: 2.5em;
  line-height: 1em;
  display: block;
}

.list .text span {
  font-size: 1.5em;
}
.list .text.small {
  margin-top: 0;
}
.list .text.small strong {
  font-size: 1.4em;
}
