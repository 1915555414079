.videoListItem {
  max-width: 260px;
  padding: 10px 20px 20px 20px;
}

@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
  .videoListItem {
    max-width: 187px;
  }
}

@media (max-width: 968px) {
  .videoListItem {
    width: 260px;
  }
}
