@media (max-width: 768px) {
  .footerLinks {
    text-align: left !important;
    margin-top: 0 !important;
  }
  .footerMax {
    width: auto !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center;
  }
  .footerBox {
    min-height: auto !important;
  }

  .footerSep {
    display: inline-block;
  }
}
