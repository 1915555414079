.mainNavigation {
  padding: 20px;
}
.mainNavigation a.Mui-selected,
.mainNavigation a.MuiTab-textColorPrimary {
  color: rgb(255, 255, 255);
}

@media (max-width: 768px) {
  .mainNavigation {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

@media (max-width: 898px) {
  .mainNavigation {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .mainNavigation .MuiTabs-flexContainer {
    justify-content: center;
  }
  .mainNavigation .MuiTabs-indicator {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1100px) {
  .mainNavigation {
    text-align: center;
    padding: 1em 2em;
  }
}
