.socialMedia {
  align-items: flex-start;
  justify-content: space-evenly;
  display: flex;
  padding: 30px;
}

@media (max-width: 768px) {
  .socialMedia {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
